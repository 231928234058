@import './site_variables.scss';

.right-left-container {
    align-items: center;
}

.map {
    flex-grow: 1;
    // border: solid black .5em
}

.service-areas {
    text-align: center;
    flex-grow: 1;
    // border: solid black .5em;
    padding: .5em;
    width: 50%;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.service-areas ul {
    list-style: none;
}

.service-areas li span {
    position: relative;
    left: -10px;
}

@media (max-width: 790px) {
    .service-areas{
        min-width: 100%;
    }
    .map {
        display: none;
    }
}

@media (max-width: 500px) {
    .service-areas{
        display: contents;
    }
}