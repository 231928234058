@import './site_variables.scss';

.footer {
    color: $theme-dark-blue;
    padding: 1em;
}

.footer a {
    color: $theme-dark-blue;
}

.footer-logo {
    width: 45vw;
}

@media (min-width: 500px) {
    .footer-logo {
        width: 40vw;
    }    
}
@media (min-width: 750px) {
    .footer-logo {
        width: 30vw;
    }    
}
@media (min-width: 950px) {
    .footer-logo {
        width: 24vw;
    }    
}
@media (min-width: 1300px) {
    .footer-logo {
        width: 18vw;
    }    
}
@media (min-width: 1600px) {
    .footer-logo {
        width: 15vw;
    }    
}
@media (min-width: 2000px) {
    .footer-logo {
        width: 12vw;
    }    
}