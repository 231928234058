$theme-dark-blue: #122d42;
$theme-mid-blue: #235a78;
$theme-dark-grey: #444444;
$theme-white: #ffffff;
$theme-light-grey: #8c8c8c;
$theme-light-blue: #00d1cd;
$theme-error-red: #b20000;

$theme-low-color: #9dc183;
$theme-mid-color: #e4cd05;
$theme-hi-color: #CD5C5C;

$theme-font-size-header: 30px;
$theme-font-size-subheader: 20px;
$theme-font-size-subsubheader: 17px;
$theme-font-size-paragraph: 17px;

$navbar-size: 65px;