@import './site_variables.scss';

.calculator-large-wrapper {
    margin-top: 2em;
    color: $theme-dark-blue;
}

.estimate-form {
    background-color: $theme-white;
    border-radius: 2px;
    border-color: $theme-mid-blue;
    border-style: solid;
    box-sizing: border-box;
    width: 40%;
    margin: 0 auto;
    padding: 1.5em;
    padding-bottom: 1em;
}

.disclaimer {
    padding-top: 2em;
}

.other-services-wrapper {
    margin-top: 3em;
}

.MuiInputBase-root {
    margin-bottom: 1em;
    margin: 0 auto;
}

#submit {
    width: 50%;
    margin: 0 auto;
}

#submit:hover {
    background-color: $theme-mid-blue;
    color: $theme-white;
}

.estimate-title {
    text-align: center;
    color: $theme-dark-blue;
    font-size: $theme-font-size-header;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    width: 40%;
    margin: 0 auto;
}

.estimate-subtitle {
    color: $theme-dark-blue;
    text-align: left;
    font-size: $theme-font-size-subsubheader;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    padding-bottom: 2em;
    width: 40%;
    margin: 0 auto;
}

.estimate-result {
    font-size: $theme-font-size-paragraph;
    display: flex;
    // flex-direction: row;
    justify-content: center;
    align-items: center;
}

.estimate-value {
    width: 33%;
    margin: 0.2em;
    color: $theme-white;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    border-radius: 1px;
    padding: 0.7em;
}

.estimate-low { 
    background-color: $theme-low-color;
}

.estimate-mid { 
    background-color: $theme-mid-color;
}

.estimate-hi { 
    background-color: $theme-hi-color;
}

.room-size { 
    margin: 0 auto;
    width: 100%;
    justify-content: center;
}

.room-size .MuiInputBase-root {
    // min-width: 200px;
}

@media (min-width: 2200px) {
    .estimate-form {
        width: 10%;
    }
    .disclaimer {
        width: 10%;
    }
}
@media (min-width: 2000px) {
    .estimate-form {
        width: 25%;
    }
    .disclaimer {
        width: 25%;
    }
}
@media (min-width: 1720px) {
    .estimate-form {
        width: 35%;
    }
    .disclaimer {
        width: 35%;
    }
}
@media (max-width: 1200px) {
    .estimate-form {
        width: 55%;
    }
    .disclaimer {
        width: 55%;
    }
}
@media (max-width: 930px) {
    .estimate-form {
        width: 70%;
    }
    .estimate-title {
        width: 70%;
    }
    .disclaimer {
        width: 70%;
    }
}
@media (max-width: 700px) {
    .estimate-form {
        width: 80%;
    }
    .estimate-title {
        width: 80%;
    }
    .disclaimer {
        width: 80%;
    }
}
@media (max-width: 530px) {
    .estimate-form {
        width: 90%;
    }
    .estimate-title {
        width: 90%;
    }
    .estimate-value {
        font-size: 16px;
    }
    .disclaimer {
        width: 90%;
        font-size: 16px;
    }
}
@media (max-width: 465px) {
    .estimate-form {
        width: 100%;
        border-style: none;
    }
    .estimate-title {
        width: 100%;
    }
    .disclaimer {
        width: 100%;
        font-size: 13px;
        padding: 0.5em
    }
}
@media (max-width: 395px) {
    .estimate-value {
        font-size: 14px;
    }
}
@media (max-width: 350px) {
    .estimate-value {
        font-size: 11px;
    }
}