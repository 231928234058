@import './site_variables.scss';

.image-grid {
    flex-grow: 1;
    // border: solid black .5em;
    padding: .5em;
    width: 50%;
}

.information {
    text-align: left;
    flex-grow: 1;
    // border: solid black .5em;
    padding: .5em;
    width: 50%;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.information a {
    color: $theme-mid-blue;
}

.information a:hover {
    color: $theme-light-blue;
}

@media (max-width: 1190px) {
    .information{
        min-width: 70%;
    }
    .image-grid {
        width: 70%;
    }
}

@media (max-width: 1050px) {
    .information{
        min-width: 80%;
    }
    .image-grid {
        width: 80%;
    }
}

@media (max-width: 900px) {
    .information{
        min-width: 90%;
    }
    .image-grid {
        width: 90%;
    }
}

@media (max-width: 790px) {
    .information{
        min-width: 100%;
    }
    .image-grid {
        display: none;
    }
}

@media (max-width: 500px) {
    .information{
        display: contents;
    }
}