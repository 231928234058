@import './site_variables.scss';

.right-left-container {
    display: flex; 
    // flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;

    width: 100%;
    margin: 0 auto;
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 0.5em;
    // padding-top: 2em;

    // border: solid black 1em;
}

@media (min-width: 1680px) {
    .right-left-container {
        width: 80%;
        margin: 0 auto;
    }
}
@media (min-width: 2100px) {
    .right-left-container {
        width: 60%;
        margin: 0 auto;
    }
}
@media (min-width: 2795px) {
    .right-left-container {
        width: 40%;
        margin: 0 auto;
    }
}
@media (max-width: 1190px) {
    .right-left-container {
        flex-direction: column;
        margin: 0 auto;
        align-items: center;
    }
}