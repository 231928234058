@import './site_variables.scss';

.banner {
    padding: 1em;
    padding-bottom: 3em;
    background-color: $theme-white;
    background-image: url("../images/bannerimages/carpet.jpeg"), url("../images/banner.png");
    background-repeat: no-repeat, no-repeat;
    background-position: 0% 0%, 100% 0%;

    min-height: 940px;
    max-height: 1080px;
}

.logo {
    width: 90%;
}

@media (max-aspect-ratio: 1/1) {
    // taller than wide
    $halfmoon-size: 50vw;
    .halfmoon {
        background-color: $theme-white;
        margin: 0 auto;
        height: $halfmoon-size;
        width: $halfmoon-size;
        border-radius: $halfmoon-size $halfmoon-size $halfmoon-size $halfmoon-size;
    }
}

@media (min-aspect-ratio: 1/1) {
    // wider than tall
    $halfmoon-size: 45vh;
    .halfmoon {
        background-color: $theme-white;
        margin: 0 auto;
        height: $halfmoon-size;
        width: $halfmoon-size;
        float: right;
        border-radius: $halfmoon-size $halfmoon-size $halfmoon-size $halfmoon-size;
    }
}

.halfmoon {
    background-color: $theme-white;
    margin: 0 auto;
    display: flex;
    align-items: center;
    transform: translateY(20%);
}


@media (max-width: 500px) {
    .halfmoon{
        transform: translateY(10%);
    }
}

@media (min-width: 1080px) {
    .halfmoon{
        transform: translateY(10%);
    }
}