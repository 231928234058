@import './site_variables.scss';

.form {
    background-color: $theme-dark-blue;
    border-radius: 2px;
    box-sizing: border-box;
    width: 50%;
    margin: 0 auto;
    padding: 1.5em;
    padding-bottom: 1em;
  }

.MuiInputBase-root {
    margin-bottom: 1em;
}

#submit {
    width: 50%;
    margin: 0 auto;
}

#submit:hover {
    background-color: $theme-mid-blue;
    color: $theme-white;
}

.title {
    text-align: left;
    color: $theme-white;
    font-size: $theme-font-size-header;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
}

.subtitle {
    color: $theme-white;
    text-align: left;
    font-size: $theme-font-size-subsubheader;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    padding-bottom: 2em;
}


@media (max-width: 1650px) {
    .form {
        width: 60%;
    }
}
@media (max-width: 1325px) {
    .form {
        width: 70%;
    }
}
@media (max-width: 1150px) {
    .form {
        width: 80%;
    }
}
@media (max-width: 900px) {
    .form {
        width: 90%;
    }
    .title {
        text-align: center;
    }
}
@media (max-width: 600px) {
    .form {
        width: 100%;
    }
}
@media (max-width: 400px) {
    .form {
        padding: 0.7em;
    }
}