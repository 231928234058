@import './site_variables.scss';

#main {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.main-item {
    flex: 1;
    position: relative;   
    padding-top: 8em;
    padding-bottom: 8em;;
}

.main-item:nth-child(even) {
    background-color: $theme-dark-blue;
    color: white;
}

.small-wrapper {
    width: 70%;
    margin: 0 auto;
}

.error {
    color: $theme-error-red;
    padding-top: 1em;
}

.success { 
    color: $theme-mid-blue;
    padding-top: 1em;
}

.space-right {
    margin-right: 0.5em;
}

.space-left { 
    margin-left: 0.5em;
}

.space-top {
    margin-top: 0.5em;
}
.space-bottom {
    margin-bottom: 0.7em;
}

// @media (max-width: 1190px) {
//     .main-item{
//         min-width: 70%;
//     }
// }

// @media (max-width: 1050px) {
//     .main-item{
//         min-width: 80%;
//     }
// }

// @media (max-width: 900px) {
//     .main-item{
//         min-width: 90%;
//     }
// }

// @media (max-width: 790px) {
//     .main-item{
//         min-width: 100%;
//     }
// }

@media (max-width: 500px) {
    .small-wrapper {
        width: 85%;
    }
    #contact {
        width: 95%;
    }
}