@import './site_variables.scss';

.alternate-contact {
    margin-top: 2em;
    color: $theme-white;
    text-align: left;
    color: $theme-white;
    text-align: left;
    font-size: $theme-font-size-subsubheader;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

.alternate-contact ul {
    list-style: none;
}

.alternate-contact li span {
    margin-right: 15px;
}

.form-field-wrapper {
    background-color: $theme-white;
    width: 70%;
    padding: 2em;
    margin: 0 auto;
    border-radius: 0px;
    box-sizing: border-box;
}

@media (max-width: 900px) {
    .form-field-wrapper {
        width: 90%;
    }
}
@media (max-width: 500px) {
    .alternate-contact li span {
        margin-right: 10px;
    }
    .form-field-wrapper {
        width: 100%;
    }
}