@import './site_variables.scss';

.navbar {
    padding: 0.5em;
    background-color: $theme-dark-blue;
    border: solid $theme-dark-blue 0.5em;
    color: $theme-white
}

.navbar-toggler {
    color: $theme-white;
}

.navbar .navbar-brand {
    padding-left: 4em;
}

.navitems {
    margin-right: 3em;
}

.navbar a {
    font-weight: 500;
    font-size: $theme-font-size-subsubheader;
    color: $theme-white;
}

.sticky {
    position: sticky;
    top: 0;
}

.navbar-wrapper {
    background-color: $theme-dark-blue;
}

.navbar-logo {
    width: 50vw;
}

@media (min-width: 500px) {
    .navbar-logo {
        width: 40vw;
    }    
}
@media (min-width: 750px) {
    .navbar-logo {
        width: 30vw;
    }    
}
@media (min-width: 950px) {
    .navbar-logo {
        width: 24vw;
    }    
}
@media (min-width: 1300px) {
    .navbar-logo {
        width: 18vw;
    }    
}
@media (min-width: 1600px) {
    .navbar-logo {
        width: 15vw;
    }    
}
@media (min-width: 2000px) {
    .navbar-logo {
        width: 12vw;
    }    
}
// media select @ 1000px wide -> logo float right, hamburger options